var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"designer--documentation"},[_c('div',{staticClass:"documentation--main"},[_vm._m(0),_c('div',{staticClass:"docs--items"},[_vm._m(1),_c('div',{staticClass:"docs--details"},[_c('p',[_vm._v("The sample panel provides available input types currently supported on the platform. These input samples are HTML 5 compatible and works seamlessly and customization.")]),_c('br'),_c('p',[_vm._v("The following input samples are currently available:")]),_c('ul',{staticClass:"docs--list"},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('li',[_c('b',[_vm._v("Date")]),_vm._v(": Takes input manually or from drop-down modal depending on browser support, eg: "+_vm._s(new Date().getFullYear()+"-"+(new Date().getMonth()+1)+"-"+new Date().getDate())+".")]),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13)]),_c('p',[_vm._v("To ensure data integrity, users should correctly label and name every inputs type to avoid duplicates. Naming and labeling inputs is discussed on the edit panel section.")])]),_c('hr',{staticStyle:{"border-bottom":"1px solid rgba(209,213,219, 1)"}}),_vm._m(14),_vm._m(15),_c('hr',{staticStyle:{"border-bottom":"1px solid rgba(209,213,219, 1)"}}),_vm._m(16),_vm._m(17),_c('hr',{staticStyle:{"border-bottom":"1px solid rgba(209,213,219, 1)"}}),_vm._m(18),_vm._m(19),_c('hr',{staticStyle:{"border-bottom":"1px solid rgba(209,213,219, 1)"}}),_vm._m(20),_vm._m(21),_c('hr',{staticStyle:{"border-bottom":"1px solid rgba(209,213,219, 1)"}})])]),_vm._m(22)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',[_vm._v("Customize Model")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"docs--item",attrs:{"id":"sample"}},[_c('h2',[_vm._v("Sample panel")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('b',[_vm._v("Time")]),_vm._v(": Takes input manually or from drop-down modal, eg: 13:00.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('b',[_vm._v("Text")]),_vm._v(": Takes input manually as single-line text input from users, eg: John Doe.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('b',[_vm._v("Email")]),_vm._v(": Takes input manually as a fields that should contain an e-mail address from users, eg: johndoe@mail.com.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('b',[_vm._v("Number")]),_vm._v(": Takes input manually as a number or unit incremented/ decremented from the input arrows, eg: 1, 2, 3.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('b',[_vm._v("File")]),_vm._v(": Allows users to upload file from their device")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('b',[_vm._v("Tel")]),_vm._v(": Takes input manually as a phone number formatted text from users, eg: +234803*******.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('b',[_vm._v("Select")]),_vm._v(": Single select sample that takes input from a preloaded drop-down.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('s',[_vm._v("Multi-Select: Not supported currently, will be added in the next update. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('s',[_vm._v("Image: Not supported currently, will be added in the next update. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('s',[_vm._v("Multiple Selection: Not supported currently, will be added in the next update. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('s',[_vm._v("Checkbox: Not supported currently, will be added in the next update. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('s',[_vm._v("Radio button: Not supported currently, will be added in the next update. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"docs--item",attrs:{"id":"panel"}},[_c('h2',[_vm._v("Edit panels")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"docs--details"},[_c('p',[_vm._v("To be customize a model, drag and drop an item from input samples onto the model panel. An edit panel will appear as soon as a sample is dropped.")]),_c('br'),_c('p',[_vm._v("The edit panel provides input options to fully customize a sample. Common customization otpions include:")]),_c('ul',{staticClass:"docs--list"},[_c('li',[_c('b',[_vm._v("Label")]),_vm._v(": The name to display on the form label, eg: Phone.")]),_c('li',[_c('b',[_vm._v("Name")]),_vm._v(": This is the name the input will be save with on the database, eg: phone_number.")]),_c('li',[_c('b',[_vm._v("Placeholder")]),_vm._v(": This is the prompt that users will see when filling out the form, eg: Enter you phone number.")]),_c('li',[_c('b',[_vm._v("Input Required")]),_vm._v(": Check this box if the input sample will be required when users fill out the form.")]),_c('li',[_c('b',[_vm._v("Multiple Selection")]),_vm._v(": Check this box if the input sample is a multi-select drop-down.")]),_c('li',[_c('b',[_vm._v("Options")]),_vm._v(": With the above select drop-down, users can add and/or remove drop-down options.")])]),_c('p',[_vm._v("When you are done customizing the panel, click on the "),_c('u',[_vm._v("Save")]),_vm._v(" button to persist your settings on the model.")]),_c('p',[_vm._v("Add more input samples to the model panel and customize each one on the edit panel.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"docs--item",attrs:{"id":"model"}},[_c('h2',[_vm._v("Model panel")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"docs--details"},[_c('p',[_vm._v("The model panel has the model title by default and should be edited in situ. The title has a 30 character limit. It also has model components if in edit model mode (check preview for more information on this).")]),_c('br'),_c('p',[_vm._v("With the model panel, users have the freedom to re-arrange input samples. Users could also remove an existing sample. A sample becomes an active on the edit panel when a user clicks on it.")]),_c('p',[_vm._v("Directly over the input sample on the model panel are three buttons marked "),_c('code',[_c('button',[_vm._v("✖")])]),_vm._v(" "),_c('code',[_c('button',[_vm._v("▲")])]),_vm._v(" "),_c('code',[_c('button',[_vm._v("▼")])])]),_c('p',[_vm._v("These are used for removing, moving up or moving a sample input (more of this on moving and removing sample section).")]),_c('p',[_vm._v("After customizing the model, click on the "),_c('u',[_vm._v("Add Model")]),_vm._v(" button to persist your settings and send model to the database.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"docs--item",attrs:{"id":"move"}},[_c('h2',[_vm._v("Move sample")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"docs--details"},[_c('p',[_vm._v("After dropping a sample on the model panel, users have the fredom to move the samples up or down to the preferred position using the arrow buttons.")]),_c('br'),_c('p',[_vm._v("The final position is the exact location a sample will assume at use time. ")]),_c('p',[_vm._v("After customizing the model, click on the "),_c('u',[_vm._v("Add Model")]),_vm._v(" button to persist your settings and send model to the database.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"docs--item",attrs:{"id":"remove"}},[_c('h2',[_vm._v("Remove sample")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"docs--details"},[_c('p',[_vm._v("The button marked "),_c('code',[_c('button',[_vm._v("✖")])]),_vm._v(" is used to remove a sample from the model panel.Users should note that this action can not be reversed once initiated.")]),_c('br'),_c('p',[_vm._v("Therefore, extra caution should be taken when clicking the button. In future release, a pop up modal will ask a user to confirm action.")]),_c('p',[_vm._v("After customizing the model, click on the "),_c('u',[_vm._v("Add Model")]),_vm._v(" button to persist your settings and send model to the database.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"documentation--nav"},[_c('div',{staticClass:"documentation--items"},[_c('div',[_c('h1',[_vm._v("On this page")])]),_c('div',{staticClass:"documentation--links"},[_c('a',{staticClass:"documentation--link",attrs:{"href":"#sample"}},[_vm._v("Sample Panel")]),_c('a',{staticClass:"documentation--link",attrs:{"href":"#panel"}},[_vm._v("Edit Panel")]),_c('a',{staticClass:"documentation--link",attrs:{"href":"#model"}},[_vm._v("Model Panel")]),_c('a',{staticClass:"documentation--link",attrs:{"href":"#move"}},[_vm._v("Move Samplel")]),_c('a',{staticClass:"documentation--link",attrs:{"href":"#remove"}},[_vm._v("Remove Sample")])])])])
}]

export { render, staticRenderFns }